<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="indigo"
      icon="mdi-account-tie"
      title="Listado de clientes"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="clientes"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-dialog
              v-model="dialog"
              scrollable
              persistent
              max-width="80%"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  <v-icon
                    dark
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title
                  class="text-h5 grey lighten-2"
                  primary-title
                >
                  {{ formTitle }}
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-subheader
                        class="text-h5 font-weight-bold"
                      >
                        Datos personales
                      </v-subheader>
                      <v-divider class="mb-4" />
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-text-field
                            v-model="editedItem.dni"
                            label="DNI/NIF/NIE"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-text-field
                            v-model="editedItem.nombre"
                            :rules="[v => !!v || 'El nombre es requerido']"
                            label="Nombres"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.apellido"
                            :rules="[v => !!v || 'El apellido es requerido']"
                            label="Apellidos"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-menu
                            ref="menuFn"
                            v-model="menuFn"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.fn"
                                label="Fecha de nacimiento"
                                hint="DD/MM/YYYY"
                                persistent-hint
                                prepend-icon="mdi-calendar-month"
                                v-bind="attrs"
                                @blur="dateFn = parseDate(editedItem.fn)"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="dateFn"
                              locale="es"
                              show-current="false"
                              @input="menuFn = false"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-select
                            v-model="editedItem.nacionalidad"
                            :items="paises"
                            item-text="nacionalidad"
                            item-value="id"
                            label="Nacionalidad"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="5"
                        >
                          <v-text-field
                            v-model="editedItem.domicilio"
                            label="Domicilio"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-select
                            v-model="editedItem.paisResidencia"
                            :items="paises"
                            item-text="pais"
                            item-value="id"
                            label="Pais de residencia"
                            @input="getProvincias"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-select
                            v-model="editedItem.provinciaResidencia"
                            :items="provincias"
                            item-text="provincia"
                            item-value="id"
                            label="Provincia de residencia"
                            @input="getLocalidades"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-select
                            v-model="editedItem.localidadResidencia"
                            :items="localidades"
                            item-text="localidad"
                            item-value="id"
                            label="Localidad de residencia"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-select
                            v-model="editedItem.estadocivil"
                            :items="estaciv"
                            item-text="nombre"
                            item-value="id"
                            label="Estado civil"
                            @input="setNombreEstadoCivil"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-show="bloqueConyuge"
                            v-model="editedItem.rem"
                            :items="rem"
                            item-text="nombre"
                            item-value="id"
                            label="Regimen economico matrimonial"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-select
                            v-show="bloqueConyuge"
                            v-model="editedItem.rforal"
                            :items="rforal"
                            item-text="nombre"
                            item-value="id"
                            label="Regimen foral"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            :rules="emailRules"
                            prepend-icon="mdi-email"
                            label="Correo electronico principal"
                            hint="Se usa para iniciar sesión"
                            persistent-hint
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.emailAlt.email"
                            prepend-icon="mdi-email"
                            append-outer-icon="mdi-plus"
                            label="Correo electronico alternativo"
                            @click:append-outer="addEmail"
                          />
                          <v-card
                            class="mx-auto"
                            max-width="90%"
                            raised
                          >
                            <v-list subheader>
                              <v-subheader>Correo electronico alternativo</v-subheader>
                              <v-divider />
                              <v-list-item
                                v-for="(item, i) in editedItem.emailAlt"
                                :key="i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.email" />
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn icon>
                                    <v-icon
                                      color="error"
                                      @click="removeEmail(i, item.id)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.telAlt.tel"
                            prepend-icon="mdi-phone"
                            label="Telefonos"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addTel"
                          />
                          <v-card
                            class="mx-auto"
                            max-width="90%"
                            raised
                          >
                            <v-list subheader>
                              <v-subheader>Telefonos</v-subheader>
                              <v-divider />
                              <v-list-item
                                v-for="(item, i) in editedItem.telAlt"
                                :key="i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.tel" />
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn icon>
                                    <v-icon
                                      color="error"
                                      @click="removeTel(i, item.id)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-expand-transition>
                        <v-subheader
                          v-show="bloqueConyuge"
                          class="text-h5 font-weight-bold mt-3"
                        >
                          Datos del conyuge
                        </v-subheader>
                      </v-expand-transition>
                      <v-expand-transition>
                        <v-divider
                          v-show="bloqueConyuge"
                          class="mb-4"
                        />
                      </v-expand-transition>
                      <v-expand-transition>
                        <v-row
                          v-show="bloqueConyuge"
                        >
                          <v-col
                            cols="12"
                            sm="6"
                            md="2"
                          >
                            <v-text-field
                              v-model="editedItem.dniConyuge"
                              label="DNI/CIF"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="editedItem.nombreConyuge"
                              label="Nombres"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="editedItem.apellidoConyuge"
                              label="Apellidos"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="2"
                          >
                            <v-menu
                              ref="menuFnConyuge"
                              v-model="menuFnConyuge"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.fnConyuge"
                                  label="Fecha de nacimiento"
                                  hint="DD/MM/YYYY"
                                  persistent-hint
                                  prepend-icon="mdi-calendar-month"
                                  v-bind="attrs"
                                  @blur="dateFnConyuge = parseDate(editedItem.fnConyuge)"
                                  v-on="on"
                                />
                              </template>
                              <v-date-picker
                                v-model="dateFnConyuge"
                                locale="es"
                                show-current="false"
                                @input="menuFnConyuge = false"
                              />
                            </v-menu>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="2"
                          >
                            <v-select
                              v-model="editedItem.paisConyuge"
                              :items="paises"
                              item-text="pais"
                              item-value="id"
                              label="Pais"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.emailConyuge"
                              prepend-icon="mdi-email"
                              label="Correo electronico principal"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.emailAltConyuge.email"
                              prepend-icon="mdi-email"
                              label="Correo electronico alternativo"
                              append-outer-icon="mdi-plus"
                              @click:append-outer="addEmailConyuge"
                            />
                            <v-card
                              class="mx-auto"
                              max-width="90%"
                              raised
                            >
                              <v-list subheader>
                                <v-subheader>Correo electronico alternativo</v-subheader>
                                <v-divider />
                                <v-list-item
                                  v-for="(item, i) in editedItem.emailAltConyuge"
                                  :key="i"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.email" />
                                  </v-list-item-content>

                                  <v-list-item-action>
                                    <v-btn icon>
                                      <v-icon
                                        color="error"
                                        @click="removeEmailConyuge(i, item.id)"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.telAltConyuge.tel"
                              prepend-icon="mdi-phone"
                              label="Telefonos"
                              append-outer-icon="mdi-plus"
                              @click:append-outer="addTelConyuge"
                            />
                            <v-card
                              class="mx-auto"
                              max-width="90%"
                              raised
                            >
                              <v-list subheader>
                                <v-subheader>Telefonos</v-subheader>
                                <v-divider />
                                <v-list-item
                                  v-for="(item, i) in editedItem.telAltConyuge"
                                  :key="i"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.tel" />
                                  </v-list-item-content>

                                  <v-list-item-action>
                                    <v-btn icon>
                                      <v-icon
                                        color="error"
                                        @click="removeTelConyuge(i, item.id)"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-expand-transition>

                      <v-subheader
                        class="text-h5 font-weight-bold mt-2"
                      >
                        Documentos
                      </v-subheader>

                      <v-divider />

                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-file-input
                            v-model="editedItem.documentos.file"
                            label="Seleccione documento"
                          />
                          <v-select
                            v-model="editedItem.documentos.tipo"
                            :items="tipos"
                            item-text="nombre"
                            item-value="id"
                            prepend-icon="mdi-format-list-bulleted-type"
                            label="Tipo de documento"
                          />
                          <v-menu
                            ref="menuExpDoc"
                            v-model="menuExpDoc"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.documentos.exp"
                                label="Fecha de vencimiento"
                                hint="DD/MM/YYYY"
                                persistent-hint
                                prepend-icon="mdi-calendar-month"
                                v-bind="attrs"
                                @blur="dateExpire = parseDate(editedItem.documentos.exp)"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="dateExpire"
                              locale="es"
                              show-current="false"
                              @input="menuExpDoc = false"
                            />
                          </v-menu>
                          <v-btn
                            block
                            class="ma-2 white--text"
                            @click="cargarDocumento"
                          >
                            Cargar documento
                            <v-icon
                              right
                              dark
                            >
                              mdi-cloud-upload
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="9"
                        >
                          <v-card
                            max-width="100%"
                            min-height="100%"
                            max-height="250px"
                            class="mx-auto overflow-y-auto"
                          >
                            <v-toolbar
                              dark
                              dense
                            >
                              <v-toolbar-title>Documentos cargados</v-toolbar-title>

                              <v-spacer />

                              <v-sheet
                                dark
                                class="text-text-caption"
                              >
                                Desplaza arriba/abajo para ver más documentos
                              </v-sheet>
                            </v-toolbar>

                            <v-list
                              v-if="editedItem.documentos.length > 0"
                              two-line
                            >
                              <v-list-item
                                v-for="(item, i) in editedItem.documentos"
                                :key="i"
                              >
                                <v-list-item-avatar>
                                  <v-btn
                                    icon
                                    color="info"
                                    @click="downloadFile(item.id, item.nombre)"
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title v-text="item.nombre" />
                                  <v-list-item-subtitle
                                    class="text--primary"
                                    v-text="item.tipo"
                                  />
                                  <v-list-item-subtitle v-text="item.exp" />
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn icon>
                                    <v-icon
                                      color="error"
                                      @click="removeFile(i, item.id)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                            <v-card-text v-else>
                              <p
                                class="text-center text-h3 mt-5 grey--text"
                              >
                                Aún no tenemos documentos cargados.
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-subheader
                        v-show="bloqueSociedad"
                        class="text-h5 font-weight-bold mt-5"
                      >
                        Datos profesionales
                      </v-subheader>
                      <v-divider
                        v-show="bloqueSociedad"
                        color="indigo"
                        class="mb-4"
                      />
                      <v-dialog
                        v-model="dialogSoc"
                        persistent
                        max-width="40%"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">Modificar sociedad</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form
                              ref="formSoc"
                              v-model="validSoc"
                              :lazy-validation="lazy"
                            >
                              <v-container>
                                <v-subheader
                                  class="text-h5 font-weight-bold"
                                >
                                  Datos de la sociedad
                                </v-subheader>
                                <v-divider class="mb-4" />
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="sociedades.cif"
                                      label="CIF"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="sociedades.denominacion"
                                      label="Denominación social"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="sociedades.domicilio"
                                      label="Domicilio social"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-select
                                      v-model="sociedades.cargo"
                                      :items="cargos"
                                      item-text="nombre"
                                      item-value="id"
                                      label="Cargo"
                                      @input="setNombreCargo"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="8"
                                  >
                                    <v-file-input
                                      v-model="sociedades.file"
                                      show-size
                                      counter
                                      :hint="sociedades.escritura"
                                      persistent-hint
                                      label="Escrituras de la sociedad"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeSoc"
                            >
                              {{ $t("close") }}
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="updateSociedad"
                            >
                              {{ $t("save") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-row v-show="bloqueSociedad">
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-text-field
                            v-model="editedItem.sociedades.cif"
                            label="CIF"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.sociedades.denominacion"
                            label="Denominación social"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.sociedades.domicilio"
                            label="Domicilio social"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-select
                            v-model="editedItem.sociedades.cargo"
                            :items="cargos"
                            item-text="nombre"
                            item-value="id"
                            label="Cargo"
                            @input="setNombreCargo"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-file-input
                            v-model="editedItem.sociedades.escritura"
                            show-size
                            counter
                            label="Escrituras de la sociedad"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="1"
                        >
                          <v-btn
                            @click="saveSociedad"
                          >
                            GUARDAR
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-dialog
                        v-model="dialogAsociado"
                        persistent
                        max-width="30%"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">Crear asociado</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form
                              ref="formAsociado"
                              v-model="validAsociado"
                              :lazy-validation="lazy"
                            >
                              <v-container>
                                <v-subheader
                                  class="text-h5 font-weight-bold"
                                >
                                  Datos del asociado
                                </v-subheader>
                                <v-divider class="mb-4" />
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="asociado.dni"
                                      label="DNI"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="8"
                                  >
                                    <v-text-field
                                      v-model="asociado.nombre"
                                      label="Nombre completo"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeAsociado"
                            >
                              {{ $t("close") }}
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveAsociado"
                            >
                              {{ $t("save") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-row v-show="bloqueSociedad">
                        <v-col
                          v-for="empresa in editedItem.sociedades"
                          :key="empresa.id"
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-card
                            max-width="600"
                            class="mx-auto"
                          >
                            <v-toolbar
                              dark
                              dense
                            >
                              <v-toolbar-title v-text="empresa.denominacion" />

                              <v-spacer />

                              <v-btn
                                icon
                                @click="editSociedad(empresa.id)"
                              >
                                <v-icon>
                                  mdi-pencil
                                </v-icon>
                              </v-btn>

                              <v-btn
                                icon
                                @click="removeSociedad(empresa.id)"
                              >
                                <v-icon>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </v-toolbar>

                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header>Datos de la empresa</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-list>
                                    <v-list-item
                                      v-for="item in empresa.info"
                                      :key="item.text"
                                    >
                                      <v-list-item-avatar>
                                        <v-icon
                                          v-text="item.icon"
                                        />
                                      </v-list-item-avatar>

                                      <v-list-item-title
                                        v-text="item.text"
                                      />

                                      <v-list-item-icon>
                                        <v-icon
                                          v-if="item.download"
                                          color="info"
                                          @click="downloadEscritura(empresa.id, item.text)"
                                        >
                                          mdi-download
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                  </v-list>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                              <v-expansion-panel>
                                <v-expansion-panel-header>Asociados</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-list>
                                    <v-list-item
                                      v-for="item in empresa.socios"
                                      :key="item.id"
                                    >
                                      <v-list-item-avatar>
                                        <v-icon>
                                          mdi-account-circle-outline
                                        </v-icon>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title v-text="item.dni" />
                                        <v-list-item-subtitle v-text="item.nombre" />
                                      </v-list-item-content>

                                      <v-list-item-action>
                                        <v-btn
                                          icon
                                          @click="removeAsociado(item.id)"
                                        >
                                          <v-icon
                                            color="error"
                                          >
                                            mdi-delete
                                          </v-icon>
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>
                                  <div class="text-right">
                                    <v-btn
                                      color="pink"
                                      fab
                                      dark
                                      small
                                      bottom
                                      right
                                      @click="addAsociado(empresa.id)"
                                    >
                                      <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <!-- <v-icon
            small
            color="error"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import MaestrosPaisesApi from '@/services/api/MaestrosPaises'
  import MaestrosRemApi from '@/services/api/MaestrosRem'
  import MaestrosRfApi from '@/services/api/MaestrosRf'
  import MaestrosRsbApi from '@/services/api/MaestrosRsb'
  import MaestrosEstadoCivilApi from '@/services/api/MaestrosEstadoCivil'
  import ClientesApi from '@/services/api/Clientes'
  import ProvinciasApi from '@/services/api/MaestrosProvincia'
  import LocalidadApi from '@/services/api/MaestrosLocalidad'
  import MaestrosTipoDocApi from '@/services/api/MaestrosTipoDocumento'
  import SociedadApi from '@/services/api/Sociedad'
  import CargosApi from '@/services/api/MaestrosCargos'

  export default {
    name: 'UsuariosClientes',

    data: vm => {
      return {
        dateFn: new Date().toISOString().substr(0, 10),
        dateFnConyuge: new Date().toISOString().substr(0, 10),
        dateExpire: new Date().toISOString().substr(0, 10),
        bloqueConyuge: false,
        bloqueSociedad: true,
        valid: true,
        validSoc: true,
        validAsociado: true,
        lazy: false,
        menuFn: false,
        menuFnConyuge: false,
        menuExpDoc: false,
        dialog: false,
        dialogSoc: false,
        dialogAsociado: false,
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        editedIndex: -1,
        clientes: [],
        paises: [],
        estaciv: [],
        rem: [],
        rsb: [],
        rforal: [],
        provincias: [],
        localidades: [],
        tipos: [],
        sociedades: [],
        cargos: [],
        asociado: [],
        headers: [
          {
            text: 'DNI/NIF/NIE',
            align: 'left',
            sortable: true,
            value: 'dni',
          },
          {
            text: 'Nombres',
            align: 'left',
            sortable: true,
            value: 'nombre',
          },
          {
            text: 'Apellidos',
            align: 'left',
            sortable: true,
            value: 'apellido',
          },
          {
            text: 'Fecha de nacimiento',
            value: 'fn',
          },
          // {
          //   text: 'Pais',
          //   value: 'paisName',
          // },
          {
            text: 'Estado civil',
            value: 'estadocivilName',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          dni: null,
          nombre: '',
          apellido: '',
          fn: vm.formatDate(new Date().toISOString().substr(0, 10)),
          pais: null,
          nacionalidad: '',
          domicilio: '',
          paisResidencia: 32,
          provinciaResidencia: null,
          localidadResidencia: null,
          estadocivil: '',
          rem: '',
          rforal: '',
          rsb: '',
          email: '',
          emailAlt: [],
          telAlt: [],
          dniConyuge: '',
          nombreConyuge: '',
          apellidoConyuge: '',
          fnConyuge: null,
          paisConyuge: null,
          emailConyuge: '',
          emailAltConyuge: [],
          telAltConyuge: [],
          documentos: [],
          sociedades: [],
          paisName: '',
          estadocivilName: '',
          tipoName: '',
          cargoName: '',
        },
        defaultItem: {
          dni: null,
          nombre: '',
          apellido: '',
          fn: vm.formatDate(new Date().toISOString().substr(0, 10)),
          pais: null,
          nacionalidad: '',
          domicilio: '',
          paisResidencia: 32,
          provinciaResidencia: null,
          localidadResidencia: null,
          estadocivil: '',
          rem: '',
          rforal: '',
          rsb: '',
          email: '',
          emailAlt: [],
          telAlt: [],
          dniConyuge: '',
          nombreConyuge: '',
          apellidoConyuge: '',
          fnConyuge: null,
          paisConyuge: null,
          emailConyuge: '',
          emailAltConyuge: [],
          telAltConyuge: [],
          documentos: [],
          sociedades: [],
          paisName: '',
          estadocivilName: '',
          tipoName: '',
          cargoName: '',
        },
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        dniRules: [
          v => !!v || 'El DNI/NIF/NIE es requerido',
          v => {
            var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
            var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
            var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
            var str = v.toString().toUpperCase()
            var isNIF = nifRexp.test(str)
            var isNIE = nieRexp.test(str)
            if (!isNIF && !isNIE) {
              return 'Debe ingresar un DNI/NIF/NIE correcto'
            }
            var nie = str
              .replace(/^[X]/, '0')
              .replace(/^[Y]/, '1')
              .replace(/^[Z]/, '2')

            var letter = str.substr(-1)
            var charIndex = parseInt(nie.substr(0, 8)) % 23

            if (validChars.charAt(charIndex) === letter) {
              return true
            }

            return 'Debe ingresar un DNI/NIF/NIE correcto'
          },
        ],
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo cliente' : 'Modificar cliente'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogSoc (val) {
        val || this.closeSoc()
      },
      dialogAsociado (val) {
        val || this.closeAsociado()
      },
      dateFn (val) {
        this.editedItem.fn = this.formatDate(this.dateFn)
      },
      dateFnConyuge (val) {
        this.editedItem.fnConyuge = this.formatDate(this.dateFnConyuge)
      },
      dateExpire (val) {
        this.editedItem.documentos.exp = this.formatDate(this.dateExpire)
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      fetchPaises () {
        MaestrosPaisesApi.getAllPais()
          .then(pais => {
            this.paises = pais
          })
          .catch(error => console.log(error))
      },
      fetchEstadoCivil () {
        MaestrosEstadoCivilApi.getAllEc()
          .then(ec => {
            this.estaciv = ec
          })
          .catch(error => console.log(error))
      },
      fetchRem () {
        MaestrosRemApi.getAllRem()
          .then(rem => {
            this.rem = rem
          })
          .catch(error => console.log(error))
      },
      fetchRf () {
        MaestrosRfApi.getAllForal()
          .then(rforal => {
            this.rforal = rforal
          })
          .catch(error => console.log(error))
      },
      fetchRsb () {
        MaestrosRsbApi.getAllRsb()
          .then(rsb => {
            this.rsb = rsb
          })
          .catch(error => console.log(error))
      },
      fetchClientes () {
        ClientesApi.getAllClientes()
          .then(clientes => {
            this.clientes = clientes
          })
          .catch(error => console.log(error))
      },
      getProvincias (i) {
        this.provincias = []
        ProvinciasApi.getProvinciasByPais(i)
          .then(provincias => {
            this.provincias = provincias
          })
          .catch(error => console.log(error))
      },
      getLocalidades (i) {
        this.localidades = []
        LocalidadApi.getLocalidadesByProvincia(i)
          .then(localidades => {
            this.localidades = localidades
          })
          .catch(error => console.log(error))
      },
      fetchTipoDoc () {
        MaestrosTipoDocApi.getAllTipoDoc()
          .then(tipos => {
            this.tipos = tipos
          })
          .catch(error => console.log(error))
      },
      fetchCargos () {
        CargosApi.getAllCargos()
          .then(cargos => {
            this.cargos = cargos
          })
          .catch(error => console.log(error))
      },
      loadSociedades (id) {
        ClientesApi.loadSociedades(id)
          .then(soc => {
            this.editedItem.sociedades = soc
          })
          .catch(error => console.log(error))
      },
      loadProvincias (pais, provincia) {
        const provi = provincia
        ProvinciasApi.getProvinciasByPais(pais)
          .then(provincias => {
            this.provincias = provincias
            this.$nextTick(function () {
              this.editedItem.provinciaResidencia = provi
            })
          })
          .catch(error => console.log(error))
      },
      loadLocalidades (provincia, localidad) {
        const loca = localidad
        LocalidadApi.getLocalidadesByProvincia(provincia)
          .then(localidades => {
            this.localidades = localidades
            this.$nextTick(function () {
              this.editedItem.localidadResidencia = loca
            })
          })
          .catch(error => console.log(error))
      },

      initialize () {
        this.fetchClientes()
        this.fetchPaises()
        this.fetchEstadoCivil()
        this.fetchRem()
        this.fetchRf()
        this.fetchRsb()
        this.fetchTipoDoc()
        this.fetchCargos()
        this.getProvincias(this.editedItem.paisResidencia)
      },

      editItem (item) {
        this.editedIndex = this.clientes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.loadSociedades(this.editedItem.id)
        this.loadProvincias(this.editedItem.paisResidencia, this.editedItem.provinciaResidencia)
        this.loadLocalidades(this.editedItem.provinciaResidencia, this.editedItem.localidadResidencia)
        if (this.editedItem.estadocivilName === 'Casado/a' || this.editedItem.estadocivilName === 'Casado') {
          this.bloqueConyuge = true
        } else {
          this.bloqueConyuge = false
        }

        var Hoy = new Date()
        var AnyoHoy = Hoy.getFullYear()
        var MesHoy = Hoy.getMonth()
        var DiaHoy = Hoy.getDate()
        var count = 0
        this.editedItem.documentos.forEach(element => {
          var fecha = element.exp.split('/')
          var fechaEval = new Date(fecha[2], fecha[1] - 1, fecha[0])

          var AnyoFecha = fechaEval.getFullYear()
          var MesFecha = fechaEval.getMonth()
          var DiaFecha = fechaEval.getDate()

          if (AnyoHoy > AnyoFecha && count === 0) {
            this.text = 'Posee documentos vencidos'
            this.snackbar = true
            this.colorSnak = 'info'
            this.timeout = -1
            count = 1
          } else {
            if (AnyoHoy === AnyoFecha && MesFecha < MesHoy && count === 0) {
              this.text = 'Posee documentos vencidos'
              this.snackbar = true
              this.colorSnak = 'info'
              this.timeout = -1
              count = 1
            } else {
              if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaFecha < DiaHoy && count === 0) {
                this.text = 'Posee documentos vencidos'
                this.snackbar = true
                this.colorSnak = 'info'
                this.timeout = -1
                count = 1
              } else {
                if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaFecha === DiaHoy && count === 0) {
                  this.text = 'Tiene documentos que vencen el día de hoy'
                  this.snackbar = true
                  this.colorSnak = 'info'
                  this.timeout = -1
                  count = 1
                }
              }
            }
          }
        })
        setTimeout(() => {
          this.timeout = 3000
        }, 10000)
      },

      deleteItem (item) {
        const index = this.clientes.indexOf(item)
        confirm('Are you sure you want to delete this item?') &&
          this.clientes.splice(index, 1)
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.$refs.form.validate()) {
          // if (this.editedItem.estadocivilName === 'Casado/a' || this.editedItem.estadocivilName === 'Casado') {
          //   if (!this.editedItem.nombreConyuge) {
          //     this.text = 'Debes ingresar el nombre del conyuge'
          //     this.snackbar = true
          //     this.colorSnak = 'error'
          //     return
          //   }
          // }
          if (this.editedIndex > -1) {
            const updClientes = this.editedItem
            const indexItem = this.editedIndex
            ClientesApi.updateClientes(this.editedItem.id, this.editedItem)
              .then(response => {
                Object.assign(this.clientes[indexItem], updClientes)
                this.text = 'Registro modificado correctamente'
                this.snackbar = true
                this.colorSnak = 'success'
              })
              .catch(error => {
                if (error.response.status === 409) {
                  this.text = error.response.data.errors
                } else {
                  this.text = 'El registro no puede ser modificado en este momento.'
                }
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else {
            const addClientes = this.editedItem
            ClientesApi.addClientes(this.editedItem)
              .then(response => {
                addClientes.id = response.id
                this.editedItem.id = response.id
                this.clientes.push(addClientes)
                this.text = 'Registro creado correctamente'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = (this.clientes.length - 1)
                // this.close()
              })
              .catch(error => {
                if (error.response.status === 409) {
                  this.text = error.response.data.errors
                } else {
                  this.text = 'El registro no puede ser creado en este momento'
                }
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
        }
      },

      addEmail () {
        var value = this.editedItem.emailAlt.email && this.editedItem.emailAlt.email.trim()
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!value || !re.test(value)) {
          this.text = 'Debes ingresar un correo electrónico valido'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el cliente.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const payload = {
          cliente: this.editedItem.id,
          email: this.editedItem.emailAlt.email,
        }

        ClientesApi.addEmailClientes(payload)
          .then(response => {
            this.editedItem.emailAlt.push({
              id: response.id,
              email: this.editedItem.emailAlt.email,
            })
            this.editedItem.emailAlt.email = ''
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeEmail (i, item) {
        const index = i
        if (confirm('Realmente desea eliminar este registro?')) {
          ClientesApi.deleteEmail(item)
            .then(response => {
              this.editedItem.emailAlt.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      addTel () {
        var value = this.editedItem.telAlt.tel && this.editedItem.telAlt.tel.trim()
        if (!value) {
          this.text = 'Debes ingresar un teléfono valido'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el cliente.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const payload = {
          cliente: this.editedItem.id,
          telefono: this.editedItem.telAlt.tel,
        }

        ClientesApi.addTelClientes(payload)
          .then(response => {
            payload.id = response.id
            this.editedItem.telAlt.push({
              id: response.id,
              tel: this.editedItem.telAlt.tel,
            })
            this.editedItem.telAlt.tel = ''
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeTel (i, item) {
        const index = i
        console.log(index)
        if (confirm('Realmente desea eliminar este registro?')) {
          ClientesApi.deleteTel(item)
            .then(response => {
              this.editedItem.telAlt.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      addEmailConyuge () {
        var value = this.editedItem.emailAltConyuge.email && this.editedItem.emailAltConyuge.email.trim()
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!value || !re.test(value)) {
          this.text = 'Debes ingresar un correo electrónico valido'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el cliente.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const payload = {
          cliente: this.editedItem.id,
          email: this.editedItem.emailAltConyuge.email,
        }

        ClientesApi.addEmailConyuge(payload)
          .then(response => {
            this.editedItem.emailAltConyuge.push({
              id: response.id,
              email: this.editedItem.emailAltConyuge.email,
            })
            this.editedItem.emailAltConyuge.email = ''
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeEmailConyuge (i, item) {
        const index = i
        if (confirm('Realmente desea eliminar este registro?')) {
          ClientesApi.deleteEmailConyuge(item)
            .then(response => {
              this.editedItem.emailAltConyuge.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      addTelConyuge () {
        var value = this.editedItem.telAltConyuge.tel && this.editedItem.telAltConyuge.tel.trim()
        if (!value) {
          this.text = 'Debes ingresar un teléfono valido'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el cliente.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const payload = {
          cliente: this.editedItem.id,
          telefono: this.editedItem.telAltConyuge.tel,
        }

        ClientesApi.addTelConyuge(payload)
          .then(response => {
            payload.id = response.id
            this.editedItem.telAltConyuge.push({
              id: response.id,
              tel: this.editedItem.telAltConyuge.tel,
            })
            this.editedItem.telAltConyuge.tel = ''
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeTelConyuge (i, item) {
        const index = i
        if (confirm('Realmente desea eliminar este registro?')) {
          ClientesApi.deleteTelConyuge(item)
            .then(response => {
              this.editedItem.telAltConyuge.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      setNombrePais (i) {
        const name = this.paises.find(e => e.id === i)
        this.editedItem.paisName = name.pais
      },

      setNombreEstadoCivil (i) {
        const name = this.estaciv.find(e => e.id === i)
        this.editedItem.estadocivilName = name.nombre
        if (name.nombre === 'Casado' || name.nombre === 'Casado/a') {
          this.bloqueConyuge = true
        } else {
          this.bloqueConyuge = false
        }
      },

      setNombreCargo (i) {
        const name = this.cargos.find(e => e.id === i)
        this.editedItem.cargoName = name.nombre
      },

      cargarDocumento () {
        const archivo = this.editedItem.documentos.file
        if (!archivo) {
          this.text = 'Debes seleccionar un documento'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const value = this.editedItem.documentos.tipo
        if (!value) {
          this.text = 'Debes seleccionar un tipo de documento'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el cliente.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const formData = new FormData()
        formData.append('_file', this.editedItem.documentos.file)
        formData.append('cliente', this.editedItem.id)
        formData.append('tipo', this.editedItem.documentos.tipo)
        formData.append('exp', this.editedItem.documentos.exp)
        formData.append('oficial', true)

        ClientesApi.uploadFile(formData)
          .then(response => {
            const name = this.tipos.find(e => e.id === this.editedItem.documentos.tipo)
            this.editedItem.tipoName = name.nombre

            this.editedItem.documentos.push({
              id: response.id,
              nombre: this.editedItem.documentos.file.name,
              tipo: this.editedItem.tipoName,
              exp: this.editedItem.documentos.exp,
              file: response.file,
            })
            this.editedItem.documentos.file = ''
            this.editedItem.documentos.tipo = ''
            this.text = 'Documento cargado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El documento no puede ser cargado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeFile (i, item) {
        const index = i
        if (confirm('Realmente desea eliminar este registro?')) {
          ClientesApi.deleteFile(item)
            .then(response => {
              this.editedItem.documentos.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },

      downloadFile (i, name) {
        const nombre = name
        ClientesApi.downloadFile(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      downloadEscritura (i, name) {
        const nombre = name
        SociedadApi.downloadEscritura(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      saveSociedad () {
        const formData = new FormData()
        formData.append('_file', this.editedItem.sociedades.escritura)
        formData.append('cliente', this.editedItem.id)
        formData.append('cif', this.editedItem.sociedades.cif)
        formData.append('denominacion', this.editedItem.sociedades.denominacion)
        formData.append('domicilio', this.editedItem.sociedades.domicilio)
        formData.append('cargo', this.editedItem.sociedades.cargo)
        formData.append('cargoName', this.editedItem.cargoName)

        SociedadApi.addSociedad(formData)
          .then(response => {
            this.editedItem.sociedades = []
            this.loadSociedades(this.editedItem.id)
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      editSociedad (item) {
        this.dialogSoc = true
        SociedadApi.getSociedad(item)
          .then(response => {
            this.sociedades = response
          })
          .catch(error => {
            console.log(error)
            this.text = 'La sociedad no puede ser cargada en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      closeSoc () {
        this.dialogSoc = false
      },

      closeAsociado () {
        this.dialogAsociado = false
      },

      updateSociedad () {
        if (this.$refs.formSoc.validate()) {
          const formData = new FormData()
          if (this.sociedades.file) {
            formData.append('_file', this.sociedades.file)
          }
          formData.append('id', this.sociedades.id)
          formData.append('cif', this.sociedades.cif)
          formData.append('denominacion', this.sociedades.denominacion)
          formData.append('domicilio', this.sociedades.domicilio)
          formData.append('cargo', this.sociedades.cargo)
          formData.append('cargoName', this.editedItem.cargoName)

          SociedadApi.updateSociedad(this.sociedades.id, formData)
            .then(response => {
              this.editedItem.sociedades = []
              this.loadSociedades(this.editedItem.id)
              this.text = 'Registro modificado correctamente'
              this.snackbar = true
              this.colorSnak = 'success'
            })
            .catch(error => {
              console.log(error)
              this.text = 'El registro no puede ser modificado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })

          this.closeSoc()
        }
      },

      removeSociedad (sociedad) {
        if (confirm('Realmente desea eliminar este registro?')) {
          ClientesApi.deleteClientesSociedad(this.editedItem.id, sociedad)
            .then(response => {
              this.editedItem.sociedades = []
              this.loadSociedades(this.editedItem.id)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      removeAsociado (asociado) {
        if (confirm('Realmente desea eliminar este registro?')) {
          ClientesApi.deleteAsociado(asociado)
            .then(response => {
              this.editedItem.sociedades = []
              this.loadSociedades(this.editedItem.id)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      addAsociado (empresa) {
        this.dialogAsociado = true
        this.asociado.empresa = empresa
      },

      saveAsociado () {
        var value = this.asociado.dni && this.asociado.dni.trim()
        if (!value) {
          this.text = 'Debe ingresar el DNI del asociado'
          this.colorSnak = 'error'
          this.snackbar = true
          return
        }

        var value2 = this.asociado.nombre && this.asociado.nombre.trim()
        if (!value2) {
          this.text = 'Debe ingresar el DNI del asociado'
          this.colorSnak = 'error'
          this.snackbar = true
          return
        }

        const payload = {
          empresa: this.asociado.empresa,
          dni: this.asociado.dni,
          nombre: this.asociado.nombre,
        }

        ClientesApi.addAsociado(payload)
          .then(response => {
            this.editedItem.sociedades = []
            this.loadSociedades(this.editedItem.id)
            this.asociado.id = ''
            this.asociado.dni = ''
            this.asociado.nombre = ''
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
